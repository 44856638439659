/* eslint-disable @typescript-eslint/no-explicit-any */
import "@ag-grid-community/styles/ag-grid.min.css";
import "@ag-grid-community/styles/ag-theme-balham.min.css";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { APP_TYPE_COOKIE_NAME, LANDING_REDIRECT_KEY } from "@enfusion-ui/core";
import { AppEvent, AppEventCategories } from "@enfusion-ui/types";
import { mobilePostMessage } from "@enfusion-ui/utils";
import { ErrorBoundary, LandingView, MobileVersionErrorView, } from "@enfusion-ui/web-components";
import { AppLogging, AuthProvider, IS_LOCAL, setCookies, validAppVersion, validAppVersionMin, } from "@enfusion-ui/web-core";
import Keycloak from "keycloak-js";
import queryString from "query-string";
import * as React from "react";
import ReactDOM from "react-dom";
import { SilentCheckSSO } from "./components/SilentCheckSSO";
const handleBoundaryError = (err) => {
    AppLogging.event({ event: AppEvent.ErrorBoundary, category: AppEventCategories.Navigation }, { message: err.message });
    AppLogging.localOnly.safeError("Error boundary", err);
};
export function loadApp(url, App, afterAuth, modifyUser, afterAuthIssue, beforeLogout) {
    const urlData = queryString.parseUrl(window.location.href);
    const mobileView = urlData.url.endsWith("/mobile-app-auth");
    const keycloak = Keycloak({
        url,
        realm: "enfusion",
        clientId: "Enfusion",
    });
    keycloak.onAuthSuccess = () => {
        ReactDOM.render(React.createElement(ErrorBoundary, { key: "main", onReset: () => {
                window.location.reload();
            }, showDetails: true, showReset: IS_LOCAL, onError: handleBoundaryError },
            React.createElement(AuthProvider, { keycloak: keycloak, afterAuth: afterAuth, modifyUser: modifyUser, mobileView: mobileView, beforeLogout: beforeLogout },
                React.createElement(App, null))), document.getElementById("root"));
    };
    keycloak.onAuthError = (errorData) => {
        afterAuthIssue?.();
        ReactDOM.render(React.createElement(ErrorBoundary, { onError: handleBoundaryError, showDetails: true, showReset: IS_LOCAL, key: "AE", hasError: errorData.error_description ?? true, onReset: () => {
                window.location.reload();
            } }), document.getElementById("root"));
    };
    keycloak.onAuthLogout = () => {
        afterAuthIssue?.();
        mobilePostMessage({
            source: "mobile-app-auth",
            action: "logged-out",
            message: "keycloak logged out",
        });
    };
    keycloak
        .init({
        onLoad: "login-required",
        checkLoginIframe: false,
        enableLogging: true,
        flow: "hybrid",
    })
        .catch((reason) => {
        ReactDOM.render(React.createElement(ErrorBoundary, { showDetails: true, key: "catch", hasError: typeof reason === "undefined"
                ? true
                : typeof reason === "string"
                    ? reason
                    : reason.message ?? true, onError: handleBoundaryError, onReset: () => {
                window.location.reload();
            } }), document.getElementById("root"));
    });
}
export function initApp(App, serviceWorker, checkRoute, afterAuth, modifyUser) {
    try {
        // Required for worker-loader
        class SharedOrNormalWorker {
            constructor(...args) {
                return new (typeof window.SharedWorker !== "undefined"
                    ? window.SharedWorker
                    : window.Worker)(...args);
            }
        }
        window.SharedOrNormalWorker = SharedOrNormalWorker;
        // set cookie for app type
        setCookies({
            [APP_TYPE_COOKIE_NAME]: window.MOBILE_APP_VERSION ? "mobile" : "web",
        });
        console.log("process.env.REACT_APP_VERSION", process.env.REACT_APP_VERSION);
        LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE ||
            "Using_this_AG_Grid_Enterprise_key_( AG-045405 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Enfusion Ltd. LLC )_is_granted_a_( Single Application )_Developer_License_for_the_application_( enfusion-web )_only_for_( 10 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( enfusion-web )_need_to_be_licensed___( enfusion-web )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 19 September 2024 )____[v2]_MTcyNjcwMDQwMDAwMA==27eebcdb9c81e9b126e2c34bee5c8b5f");
        function setViewSize() {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            const vh = window.innerHeight * 0.01;
            const vw = window.innerWidth * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            document.documentElement.style.setProperty("--vw", `${vw}px`);
        }
        window.addEventListener("resize", setViewSize);
        setViewSize();
        const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL ||
            "https://login-prod-us01.enfusionsystems.com/auth";
        function render(element) {
            ReactDOM.render(element, document.getElementById("root"));
        }
        if (validAppVersionMin()) {
            if (window.location.pathname.includes("/reset")) {
                localStorage.clear();
                sessionStorage.clear();
                render(React.createElement(LandingView, null));
            }
            else if (window.location.pathname.includes("/landing")) {
                render(React.createElement(LandingView, null));
            }
            else if (window.location.pathname.endsWith("/silent-check-sso")) {
                render(React.createElement(SilentCheckSSO, null));
            }
            else {
                if (!checkRoute?.(render)) {
                    localStorage.setItem(LANDING_REDIRECT_KEY, window.location.href);
                    loadApp(keycloakUrl, App, afterAuth, modifyUser);
                }
            }
            if (!validAppVersion()) {
                AppLogging.remoteOnly.event({
                    event: AppEvent.MobileVersionOutOfDate,
                    category: AppEventCategories.Navigation,
                }, {
                    version: window.MOBILE_APP_VERSION,
                    currentVersion: process.env.REACT_APP_MOBILE_APP_CURRENT_VERSION,
                });
                mobilePostMessage({
                    source: "mobile-app-auth",
                    action: "version-out-of-date",
                    payload: `The current version of the mobile app you are on ${window.MOBILE_APP_VERSION} is out of date. Please update from the app store.`,
                    version: process.env.REACT_APP_MOBILE_APP_CURRENT_VERSION,
                });
            }
        }
        else {
            AppLogging.remoteOnly.event({
                event: AppEvent.MobileVersionNotSupported,
                category: AppEventCategories.Navigation,
            }, {
                version: window.MOBILE_APP_VERSION,
                reqVersion: process.env.REACT_APP_MOBILE_APP_MIN_VERSION,
            });
            mobilePostMessage({
                source: "mobile-app-auth",
                action: "version-not-supported",
                payload: `The current version of the mobile app you are on ${window.MOBILE_APP_VERSION} is no longer supported. Please update from the app store.`,
            });
            render(React.createElement(MobileVersionErrorView, null));
        }
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker?.register();
    }
    catch (err) {
        AppLogging.localOnly.safeError("initApp error", err);
    }
}
const NonAuthAppCore = () => {
    return React.createElement("div", null);
};
export function initNonAuthApp(App, serviceWorker, checkRoute) {
    return initApp(NonAuthAppCore, serviceWorker, (render) => {
        if (!checkRoute?.(render))
            render(React.createElement(App, null));
        return true;
    });
}
